import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Spinner } from "react-bootstrap";
import classes from "../RegisterNew.module.css";
import { editUserWeb } from "../../../../redux/actions/authActions";
import { countryList } from "../../../../config/constants";
import { isMobileOnly } from "react-device-detect";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (!!localStorage.userData) {
      setUserData(prevState => {
        return {
          ...prevState,
          ...JSON.parse(localStorage.userData),
        };
      });
    }
  }, []);

  useEffect(() => {
    setErrors({});

    if (Object.keys(props.success).length > 0) {
      if (props.success.step === 2) {
        const currentData = JSON.parse(localStorage.userData);
        localStorage.setItem(
          "userData",
          JSON.stringify({ ...currentData, ...props.success })
        );
        props.nextStep(userData.livingPeriod === "<3" ? 1 : 2);
      }
    }

    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
      setDisableButton(false);
    }
  }, [props, userData.livingPeriod]);

  const handleChange = (e, target) => {
    let name;
    let value;

    if (target === "country") {
      name = target;
      value = e.value;
    } else if (target === "livingPeriod") {
      name = target;
      value = e;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    setUserData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (userData.city.length > 30) alert("City can only have 30 characters!");

    setDisableButton(true);

    const editUser = {
      step: 2,
      subStep: 3,
      userId: props.auth.user.id,
      addressNo: userData.addressNo,
      address: userData.address,
      city: userData.city,
      postcode: userData.postcode,
      livingPeriod: userData.livingPeriod,
    };

    props.editUserWeb(editUser);
  };

  return (
    <>
      {isMobileOnly ? (
        <>
          <h1 className={classes.h1}>Personal details</h1>
          <h2 className={classes.h2}>Residential address</h2>
        </>
      ) : (
        <h1 className={classes.h1}>Residential address</h1>
      )}

      <Card className={classes.card}>
        <Card.Body>
          <Form onSubmit={e => handleSubmit(e)}>
            <Form.Text className="pb-4">
              Please ensure the address written is the same as the one displayed
              on your supporting documents which we will request later on.
            </Form.Text>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>
                  First Line Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="addressNo"
                  required
                  onChange={e => handleChange(e, "")}
                  defaultValue={userData.addressNo}
                />
                <Form.Text style={{ color: "red" }}>{errors.address}</Form.Text>
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12">
                <Form.Label className={classes.label}>
                  Second Line Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  onChange={e => handleChange(e, "")}
                  defaultValue={userData.address}
                />
                <Form.Text style={{ color: "red" }}>{errors.address}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" xs="12" className="mt-4">
                <Form.Label className={classes.label}>Town / City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  required
                  onChange={e => handleChange(e, "")}
                  defaultValue={userData.city}
                  maxLength="30"
                />
                <Form.Text style={{ color: "red" }}>{errors.city}</Form.Text>
              </Form.Group>
              <Form.Group as={Col} md="6" xs="12" className="mt-4">
                <Form.Label className={classes.label}>
                  Postal code / Zip code
                </Form.Label>
                <Form.Control
                  type="text"
                  name="postcode"
                  required
                  onChange={e => handleChange(e, "")}
                  defaultValue={userData.postcode}
                />
                <Form.Text style={{ color: "red" }}>
                  {errors.postcode}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="mt-4">
                <Form.Label className={classes.label}>
                  Country of residence
                </Form.Label>
                <Form.Text>{countryList[userData.country]}</Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} className="mt-4 mb-0">
                <Form.Label className={classes.label}>
                  How long have you been living at this address?
                </Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group
                as={Col}
                onClick={() => handleChange("<3", "livingPeriod")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.livingPeriod && userData.livingPeriod === "<3"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxTop}>LESS THAN</span>
                  <span className={classes.bigChoiceBoxMiddle}>3</span>
                  <span className={classes.bigChoiceBoxBottom}>Years</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                onClick={() => handleChange("3-5", "livingPeriod")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.livingPeriod && userData.livingPeriod === "3-5"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxTop}>BETWEEN</span>
                  <span className={classes.bigChoiceBoxMiddle}>3-5</span>
                  <span className={classes.bigChoiceBoxBottom}>Years</span>
                </Form.Text>
              </Form.Group>
              <Form.Group
                as={Col}
                onClick={() => handleChange("5+", "livingPeriod")}
              >
                <Form.Text
                  className={[
                    classes.bigChoiceBox,
                    userData.livingPeriod && userData.livingPeriod === "5+"
                      ? classes.choiceBoxActive
                      : "",
                  ].join(" ")}
                >
                  <span className={classes.bigChoiceBoxTop}>MORE THAN</span>
                  <span className={classes.bigChoiceBoxMiddle}>5</span>
                  <span className={classes.bigChoiceBoxBottom}>Years</span>
                </Form.Text>
              </Form.Group>
            </Form.Row>
            <Form.Text style={{ color: "red" }}>
              {errors.livingPeriod}
            </Form.Text>

            <Form.Text style={{ color: "red" }}>
              {errors.server}
              {errors.userId}
            </Form.Text>

            <Button
              variant="link"
              className="simpleBackButton"
              onClick={() => props.nextStep(-1)}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="blueButton"
              disabled={disableButton}
            >
              {disableButton ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  NEXT
                </>
              ) : (
                "NEXT"
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  editUserWeb: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { editUserWeb })(Step);
